///
/// Dimension by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Button */

input[type='submit'],
input[type='reset'],
input[type='button'],
button,
.button {
  @include vendor('appearance', 'none');
  @include vendor(
                  'transition',
                  'background-color #{_duration(transition)} ease-in-out, color #{_duration(transition)} ease-in-out'
  );
  background-color: transparent;
  border-radius: _size(border-radius);
  border: 0;
  box-shadow: inset 0 0 0 _size(border-width) _palette(border);
  color: _palette(fg-bold) !important;
  cursor: pointer;
  display: inline-block;
  font-size: 0.8rem;
  font-weight: _font(weight);
  height: _size(element-height);
  letter-spacing: _font(letter-spacing);
  line-height: _size(element-height);
  outline: 0;
  padding: 0 1.25rem 0 (1.25rem + (_font(letter-spacing) * 0.5));
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;

  &:hover {
    background-color: _palette(border-bg);
  }

  &:active {
    background-color: _palette(border-bg-alt);
  }

  &.icon {
    &:before {
      margin-right: 0.5em;
    }
  }

  &.fit {
    width: 100%;
  }

  &.small {
    font-size: 0.6rem;
    height: (_size(element-height) * 0.75);
    line-height: (_size(element-height) * 0.75);
  }

  &.primary {
    background-color: _palette(fg-bold);
    color: _palette(bg) !important;
    font-weight: _font(weight-bold);

    &:hover {
    }

    &:active {
    }
  }

  &.disabled,
  &:disabled {
    @include vendor('pointer-events', 'none');
    cursor: default;
    opacity: 0.25;
  }
}

input[type='submit'],
input[type='reset'],
input[type='button'],
button {
  line-height: calc(#{_size(element-height)} - 2px);
}
