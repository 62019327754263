/* BG */
#bg {
  &:after {
    background-image: url('../../assets/images/chengdu001.webp');
  }
}

.icon {
  line-height: inherit;

  &:before {
    font-family: icomoon;
  }
}

html.zh-cn {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: _font(weight);
  }

  h3 {
    border-bottom: dashed _size(border-width) _palette(border);
    width: max-content;
    padding-bottom: 0.5rem;
  }

  blockquote p:last-child {
    margin-bottom: 0;
  }

  p {
    font-size: 0.9rem;
    text-indent: 2em;
  }

  #header {
    p {
      font-size: 1rem;
      text-indent: 0;
    }
  }

  #contact,
  #works {
    p {
      text-indent: 0;
    }

    i {
      margin-right: 0.5rem;
    }
  }
}
