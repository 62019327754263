///
/// Dimension by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Icons */

ul.icons {
  cursor: default;
  list-style: none;
  padding-left: 0;

  li {
    display: inline-block;
    padding: 0 0.75em 0 0;

    &:last-child {
      padding-right: 0;
    }

    a {
      border-radius: 100%;
      box-shadow: inset 0 0 0 _size(border-width) _palette(border);
      display: inline-block;
      height: 2.25rem;
      line-height: 2.25rem;
      text-align: center;
      width: 2.25rem;

      &:hover {
        background-color: _palette(border-bg);
      }

      &:active {
        background-color: _palette(border-bg-alt);
      }
    }
  }
}
