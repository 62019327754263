///
/// Dimension by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Actions */

ul.actions {
  @include vendor('display', 'flex');
  cursor: default;
  list-style: none;
  margin-left: (_size(element-margin) * -0.5);
  padding-left: 0;

  li {
    padding: 0 0 0 (_size(element-margin) * 0.5);
    vertical-align: middle;
  }

  &.special {
    @include vendor('justify-content', 'center');
    width: 100%;
    margin-left: 0;

    li {
      &:first-child {
        padding-left: 0;
      }
    }
  }

  &.stacked {
    @include vendor('flex-direction', 'column');
    margin-left: 0;

    li {
      padding: (_size(element-margin) * 0.65) 0 0 0;

      &:first-child {
        padding-top: 0;
      }
    }
  }

  &.fit {
    width: calc(100% + #{_size(element-margin) * 0.5});

    li {
      @include vendor('flex-grow', '1');
      @include vendor('flex-shrink', '1');
      width: 100%;

      > * {
        width: 100%;
      }
    }

    &.stacked {
      width: 100%;
    }
  }

  @include breakpoint('<=xsmall') {
    &:not(.fixed) {
      @include vendor('flex-direction', 'column');
      margin-left: 0;
      width: 100% !important;

      li {
        @include vendor('flex-grow', '1');
        @include vendor('flex-shrink', '1');
        padding: (_size(element-margin) * 0.5) 0 0 0;
        text-align: center;
        width: 100%;

        > * {
          width: 100%;
        }

        &:first-child {
          padding-top: 0;
        }

        input[type='submit'],
        input[type='reset'],
        input[type='button'],
        button,
        .button {
          width: 100%;

          &.icon {
            &:before {
              margin-left: -0.5em;
            }
          }
        }
      }
    }
  }
}
