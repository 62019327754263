///
/// Dimension by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Basic */

// MSIE: Required for IEMobile.
@-ms-viewport {
  width: device-width;
}

// Ensures page width is always >=320px.
@include breakpoint('<=xsmall') {
  html, body {
    min-width: 320px;
  }
}

// Set box model to border-box.
// Based on css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  background: _palette(bg);

  // Stops initial animations until page loads.
  &.is-preload {
    *, *:before, *:after {
      @include vendor('animation', 'none !important');
      @include vendor('transition', 'none !important');
    }
  }

}
